<template>
    <div class="container mx-auto mt-6 px-4 sm:px-6 lg:px-8">
        <form @submit.prevent="savePost" class="bg-white shadow-md rounded-md p-6 space-y-6">
            <h2 class="text-2xl font-semibold text-gray-800">发布文章</h2>

            <div>
                <input type="text" id="title" v-model="title" required placeholder="标题"
                    class="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2 bg-gray-50" />
            </div>

            <div>
                <BitfreeMdEditor v-model:md-text="content" />
            </div>

            <div v-if="errorMessage" class="mt-4 p-2 bg-red-100 rounded-lg text-red-800">
                <p>{{ errorMessage }}</p>
                <button @click="clearErrorMessage"
                    class="ml-2 px-2 py-1 rounded-md text-red-600 hover:text-red-700">关闭</button>
            </div>

            <div class="flex justify-end">
                <button type="submit"
                    class="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-md">保存</button>
            </div>
        </form>
    </div>
</template>

<script>
import BitfreeMdEditor from '../components/BitfreeMdEditor.vue';
import { addPost } from '@/openapi/post';

export default {
    data() {
        return {
            title: '',
            content: '',
            errorMessage: ''
        };
    },
    components: {
        BitfreeMdEditor
    },
    methods: {
        clearErrorMessage() {
            this.errorMessage = '';
        },
        validateContent() {
            if (!this.content || this.content.trim() === '') {
                this.errorMessage = '内容不能为空。';
                return false;
            }

            const minLength = 10;
            if (this.content.length < minLength) {
                this.errorMessage = `内容不能少于 ${minLength} 个字符。`;
                return false;
            }

            this.errorMessage = '';
            return true;
        },
        async savePost() {
            if (!this.validateContent()) {
                return;
            }

            // 弹出确认对话框
            const isConfirmed = confirm('确定要保存这篇文章吗？');

            if (isConfirmed) {
                this.performSave();
            }
        },
        async performSave() {
            try {
                await addPost({
                    title: this.title,
                    content: this.content,
                    topicId: 1
                });

                this.title = '';
                this.content = '';
                alert('文章已保存！'); // 临时提示，实际应用中应替换为实际反馈
                this.$router.push('/post'); // 发帖成功后返回主页或列表页
            } catch (error) {
                console.error('Error saving the post:', error);
                alert('保存失败，请稍后再试。');
            }
        },
    }
};
</script>
