<template>
    <div class="container mx-auto p-8">
        <div class="max-w-md mx-auto bg-white rounded-lg shadow-md border border-gray-300 p-6 dark:bg-gray-200">
            <h2 class="text-2xl font-bold mb-4 text-center">审核列表</h2>
            <ul class="list-disc pl-6 mb-4">
                <li v-if="!canVerify" class="mb-2 text-red-500 text-center text-3xl">极海预约面试评估审核权限</li>
                <div v-else v-for="item in displayedItems" :key="item.id" class="mb-2 cursor-pointer"
                    @click="questionDetail(item)">
                    <span class="block text-left">{{ item.name }}</span>
                </div>
            </ul>
            <div class="flex justify-between">
                <button @click="previousPage" :disabled="currentPage === 1"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none">
                    上一页
                </button>
                <button @click="nextPage" :disabled="currentPage === totalPages"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none">
                    下一页
                </button>
            </div>
        </div>

        <!-- Modal -->
        <transition name="fade">
            <div v-if="showModal" class="fixed inset-0 flex items-center justify-center z-50">
                <div class="bg-black opacity-50 absolute inset-0"></div>
                <div class="bg-white rounded-lg p-6 max-w-3xl w-full relative dark:bg-gray-200">
                    <h3 class="text-2xl font-bold mb-4">{{ selectedItem.title }}</h3>
                    <!-- 增加内容区域的高度 -->
                    <div class="max-h-[600px] overflow-y-auto pb-4">
                        <BitfreeMdPreview class="p-3 text-left" :mdText="selectedItem.content" />
                    </div>
                    <div class="flex justify-between">
                        <!-- TODO 暂时无法审核，需要关联parent_id和level -->
                        <button @click="approveItem(selectedItem)" disabled
                            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none">
                            审核通过
                        </button>
                        <button @click="closeModal"
                            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none">
                            关闭
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import { loadVerifyList, verify } from '@/openapi/question';
import BitfreeMdPreview from './BitfreeMdPreview.vue';
export default {
    components: {
        BitfreeMdPreview
    },
    data() {
        return {
            items: [
                { id: 1, title: 'Item 1', content: 'Content for Item 1' }
            ],
            currentPage: 1,
            itemsPerPage: 5,
            canVerify: false,
            showModal: false,
            selectedItem: {}
        };
    },
    created() {
        this.loadUser();
        if (!this.canVerify) return
        loadVerifyList({ page: this.currentPage, size: this.itemsPerPage }).then(res => {
            this.items = res.data.data.list;
        })
    },
    computed: {
        totalPages() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        },
        displayedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.items.slice(startIndex, endIndex);
        }
    },
    methods: {
        loadVerifyList() {
            loadVerifyList({ page: this.currentPage, size: this.itemsPerPage }).then(res => {
                this.items = res.data.data.list;
            })
        },
        loadUser() {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                this.canVerify = user.canVerified;
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        approveItem(item) {
            // 处理审核通过逻辑
            const doVerify = () => {
                verify({ id: item.id, status: item.status }).then(resp => {
                    this.showModal = false
                    this.loadVerifyList()
                })
            }

            if (confirm('确定审核通过？')) {
                doVerify();
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        questionDetail(item) {
            this.selectedItem = item;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        }
    }
}
</script>

<style scoped>
/* 自定义样式 */
button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* 外部容器样式 */
.max-w-md {
    padding: 2rem;
    border: 1px solid #eaeaea;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 列表项样式 */
.list-disc li {
    padding-left: 1rem;
    list-style-position: inside;
}

/* Modal transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>