import axios from "axios";
import router from "@/router/router";
import timerManager from "@/common/timerManager";
import { getRedirecting, setLoggedIn, setRedirecting } from "@/common/globalState";
import Cookies from 'js-cookie';


const http = axios.create({
    baseURL: "/api",
    withCredentials: true,
    timeout: 5000
});

http.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

http.interceptors.response.use(function (response) {
    if (response.data.code === 4003) {
         // 删除所有Cookie
         for (let key in Cookies.get()) {
            Cookies.remove(key);
        }
        const isRedirecting = getRedirecting();
        // 跳转到 login
        router.replace({
            path: '/home',
        });
        if (!isRedirecting) {
            timerManager.clearAll();
            alert(response.data.message);
        }
        setRedirecting(true);
        setLoggedIn(false);
        return new Promise(() => { });
    }
    if (response.data.code === 4004) {
        // 这里页面需要依赖code去处理
        return response;
    }
    /* 返回的 runtime expection 的错误信息 */
    if (response.data.code !== 200) {
        alert(response.data.message);
        return ;
        // return Promise.reject(response.data.message);
    }
    return response;
}, function (error) {
    return Promise.reject(error);
});

export default http;