import http from "@/plugins/http";

export async function getQuestionDetail(params) {
    return http.get('/question/detail', {
        params
    });
}

export async function getQuestionTree() {
    return http.get('/question/getTree');
}

export async function addQuestion(quesiton) {
    return http.post('/question/add', quesiton)
}

export async function loadVerifyList(params) {
    return http.get('question/verifyingList', { params })
}

export async function verify(params) {
    return http.post('question/verify', params)
}