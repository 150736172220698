<template>

    <div class="container mx-auto px-4 py-8">
        <h1 class="text-2xl font-medium">请选择您需要的版本</h1>
        <p class="text-2xl font-bold mt-4 text-red-500">限时优惠中</p>

        <div class="flex flex-wrap justify-center mt-5">

            <div class="w-full md:w-1/2 xl:w-1/3 p-4">
                <div class="border border-blue-500 rounded-lg shadow-md bg-white p-6">
                    <p class="text-3xl font-bold mt-5 text-gray-500">旗舰版</p>
                    <h2 class="text-xl font-medium mb-4">
                        <p class="text-4xl font-bold mt-5"><span class="line-through text-2xl">¥1499</span><span
                                class="text-red-500">1399</span>/<span class="text-sm">年</span></p>
                    </h2>
                    <ul class="list-disc ml-5 pl-5 mt-10 text-left">
                        <li class="m-5 text-2xl">社区周会</li>
                        <li class="m-5 text-2xl">Github仓库开发权限</li>
                        <li class="m-5 text-2xl">一对一辅导5折</li>
                        <li class="m-5 text-2xl">社区聊天交流</li>
                        <li class="m-5 text-2xl">面试视频 & 解析</li>
                        <li class="m-5 text-2xl">面试题库</li>
                        <li class="m-5 text-2xl">技术分享视频</li>
                    </ul>
                    <button @click="qjDialog = true"
                        class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4">下单</button>
                </div>
            </div>

            <div class="w-full md:w-1/2 xl:w-1/3 p-4">
                <div class="border border-blue-500 rounded-lg shadow-md bg-white p-6">
                    <p class="text-3xl font-bold mt-5 text-gray-500">限量版</p>
                    <h2 class="text-xl font-medium mb-4">
                        <p class="text-4xl font-bold mt-5"><span class="line-through text-2xl">¥2199</span><span
                                class="text-red-500">1999</span>/<span class="text-sm">年</span></p>
                    </h2>
                    <ul class="list-disc ml-5 pl-5 mt-10 text-left">
                        <li class="m-5 text-2xl">2场极海1v1训练(1年内有效)</li>
                        <li class="m-5 text-2xl">社区周会</li>
                        <li class="m-5 text-2xl">一对一辅导4折</li>
                        <li class="m-5 text-2xl">Github仓库开发权限</li>
                        <li class="m-5 text-2xl">面试视频 & 解析</li>
                        <li class="m-5 text-2xl">面试题库</li>
                        <li class="m-5 text-2xl">技术分享视频</li>
                        <li class="m-5 text-2xl">社区聊天交流</li>
                        <li class="m-5 text-2xl">其它</li>
                    </ul>
                    <button @click="xlDialog = true"
                        class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4">下单</button>
                </div>
            </div>

            <div class="w-full md:w-1/2 xl:w-1/3 p-4">
                <div class="border border-blue-500 rounded-lg shadow-md bg-white p-6">
                    <p class="text-3xl font-bold mt-5 text-gray-500">社区版</p>
                    <h2 class="text-xl font-medium mb-4">
                        <p class="text-4xl font-bold mt-5"><span class="line-through text-2xl">¥899</span><span
                                class="text-red-500">799</span>/<span class="text-sm">年</span></p>
                    </h2>
                    <ul class="list-disc ml-5 pl-5 mt-10 text-left">
                        <li class="m-5 text-2xl">社区周会旁听</li>
                        <li class="m-5 text-2xl">面试视频 & 解析</li>
                        <li class="m-5 text-2xl">社区聊天交流</li>
                        <li class="m-5 text-2xl">面试题库</li>
                    </ul>
                    <button @click="sqDialog = true"
                        class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4">下单</button>
                </div>
            </div>

        </div>

        <!-- <div class="mt-12">
            <h3 class="text-lg font-medium">Accepted Payments:</h3>
            <div class="flex justify-center mt-4 space-x-4">
                <img src="https://via.placeholder.com/50x20.png?text=Visa" alt="Visa">
                <img src="https://via.placeholder.com/50x20.png?text=Mastercard" alt="Mastercard">
                <img src="https://via.placeholder.com/50x20.png?text=PayPal" alt="PayPal">
                <img src="https://via.placeholder.com/50x20.png?text=Apple+Pay" alt="Apple Pay">
                <img src="https://via.placeholder.com/50x20.png?text=AMEX" alt="American Express">
            </div>
        </div> -->

        <transition name="popup-fade">
            <div v-if="xlDialog"
                class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                <div class="bg-white p-6 rounded shadow-lg max-w-md w-full">
                    <p class="text-red-500 mb-10 text-2xl">请扫码备注邮箱，2小时内查收邮件。</p>
                    <div class="hidden sm:block">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/xl_alipay.JPG" alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/xl_wepay.JPG" alt="微信支付图片">
                            </div>
                        </div>
                    </div>
                    <div class="sm:block md:hidden lg:hidden">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/xl_alipay.JPG" style="width:50%"
                                    alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/xl_wepay.JPG" style="width:50%"
                                    alt="微信支付图片">
                            </div>
                        </div>
                    </div>

                    <button @click="xlDialog = false"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">确认</button>
                </div>
            </div>
        </transition>

        <transition name="popup-fade">
            <div v-if="sqDialog"
                class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                <div class="bg-white p-6 rounded shadow-lg max-w-md w-full">
                    <p class="text-red-500 mb-10 text-2xl">请扫码备注邮箱，2小时内查收邮件。</p>
                    <div class="hidden sm:block">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/sq_alipay.JPG" alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/sq_wepay.JPG" alt="微信支付图片">
                            </div>
                        </div>
                    </div>
                    <div class="sm:block md:hidden lg:hidden">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/sq_alipay.JPG" style="width:50%"
                                    alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/sq_wepay.JPG" style="width:50%"
                                    alt="微信支付图片">
                            </div>
                        </div>
                    </div>

                    <button @click="sqDialog = false"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">确认</button>
                </div>
            </div>
        </transition>


        <transition name="popup-fade">
            <div v-if="qjDialog"
                class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                <div class="bg-white p-6 rounded shadow-lg max-w-md w-full">
                    <p class="text-red-500 mb-10 text-2xl">请扫码备注邮箱，2小时内查收邮件。</p>
                    <div class="hidden sm:block">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/qj_alipay.JPG" alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/qj_wepay.JPG" alt="微信支付图片">
                            </div>
                        </div>
                    </div>
                    <div class="sm:block md:hidden lg:hidden">
                        <div class="flex flex-col lg:flex-row items-center justify-center gap-4">
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover " src="/static/qj_alipay.JPG" style="width:50%"
                                    alt="支付宝图片">
                            </div>
                            <div class="basis-1/2  lg:basis-full flex justify-center">
                                <img class="h-auto object-cover" src="/static/qj_wepay.JPG" style="width:50%"
                                    alt="微信支付图片">
                            </div>
                        </div>
                    </div>

                    <button @click="qjDialog = false"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">确认</button>
                </div>
            </div>
        </transition>

        <p class="text-center mt-12 text-sm text-gray-600">bitfree copyright.</p>
    </div>

</template>


<script>
export default {
    data() {
        return {
            ultimateDialog: false,
            communityDialog: false,
            sqDialog: false,
            qjDialog: false,
            xlDialog: false
        };
    },
    methods: {
    },
};
</script>
