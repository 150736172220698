import http from "@/plugins/http";

export async function uploadImage(file, bizCode) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('bizCode', bizCode);
    return http.post('/image/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}