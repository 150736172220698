const timerManager = {
    timers: {},

    setInterval(name, fn, delay) {
        if (this.timers[name]) {
            // todo 决定是否需要清理还是返回，清理的话可以重新添加
            return;
        }
        this.timers[name] = setInterval(fn, delay);
        return this.timers[name];
    },

    clearInterval(name) {
        if (this.timers[name]) {
            clearInterval(this.timers[name]);
            delete this.timers[name];
        }
    },

    clearAll() {
        for (let key in this.timers) {
            clearInterval(this.timers[key]);
        }
        this.timers = {};
    }
};


export default timerManager;