<template>
  <div class="container mx-auto dark:bg-gray-300">
    <div v-if="loading" class="flex justify-center items-center h-screen">
      <div class="spinner"></div>
    </div>

    <div v-else class="grid grid-cols-1 gap-6 mt-6">
      <div v-for="post in paginatedPosts" :key="post.id"
        class="bg-white border-2 shadow-md rounded-md p-6 cursor-pointer dark:bg-slate-300 dark:border-slate-300"
        @click="postDetail(post.id)">
        <div class="flex items-center">
          <div class="flex items-center mb-6">
            <img :src="post.avatar" :alt="post.avatar" class="rounded-full h-12 w-12 mr-4 object-cover" />
          </div>
          <span class="font-bold mr-auto">{{ post.creatorName }}</span>
          <div class="flex">
            <div class="text-1xl font-medium w-full">
              {{ post.title }}
            </div>
          </div>
          <span class="text-gray-600 text-sm ml-auto">{{ $formatDate(post.createTime) }}</span>
        </div>
      </div>
    </div>
    <div class="mt-6 flex justify-center">
      <button v-if="currentPage > 1" @click="fetchPosts(currentPage - 1)"
        class="px-4 py-2 bg-gray-200 rounded-l-md text-gray-700 hover:bg-gray-300">&laquo; 上一页</button>

      <button v-if="currentPage < totalPages" @click="fetchPosts(currentPage + 1)"
        class="px-4 py-2 bg-gray-200 rounded-r-md text-gray-700 hover:bg-gray-300">下一页 &raquo;</button>
    </div>
  </div>

  <div class="fixed bottom-8 right-8 z-10">
    <div class="flex items-center">
      <button @click="openPostForm" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
        发帖
      </button>
      <div v-if="showSearch" class="relative ml-2">
        <input type="text" v-model="searchText" placeholder="搜索帖子"
          class="border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:border-blue-500">
        <button @click="performSearch"
          class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-transparent hover:text-blue-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
        </button>
      </div>
      <button @click="toggleSearch"
        class="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded-full ml-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
        </svg>
      </button>
    </div>
  </div>
</template>


<script>
import { queryPostByPage } from '@/openapi/post';
export default {
  name: "post",
  data() {
    return {
      posts: [],
      paginatedPosts: [],
      currentPage: 1,
      totalPages: 1,
      pageSize: 10, // 每页显示的帖子数量
      loading: true,
      title: '',
      content: '',
      errorMessage: '',// 新增的错误消息
      showSearch: false,
      searchText: ''
    };
  },

  async mounted() {
    await this.fetchPosts(this.currentPage);
  },
  methods: {
    async fetchPosts(page) {
      this.loading = true;
      try {
        const response = await queryPostByPage({
          page: page,
          size: this.pageSize,
          searchText: this.searchText
        })
        this.posts = response.data.data.list;
        this.totalPages = Math.ceil(response.data.data.total / this.pageSize);
        this.paginatedPosts = response.data.data.list;
        this.currentPage = page;
        this.loading = false
      } catch (error) {
        console.error('Error fetching posts:', error);
        this.loading = false;
      }
    },
    clearErrorMessage() {
      this.errorMessage = '';
    },
    openPostForm() {
      this.$router.push('/new-post');
    },
    postDetail(id) {
      this.$router.push({
        path: "postDetail", query: {
          id: id
        }
      })
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
    },
    performSearch() {
      // 实现搜索逻辑
      this.fetchPosts(this.currentPage, this.searchText)
    }
  }
};
</script>


<style scoped>
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
