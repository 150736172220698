<template>
  <ul class="tree-view">
    <TreeNode v-for="(item, index) in flattenTree" :key="index" :node="item" :expandedSet="expandedSet"
      @select="onSelect" @toggle-expand="onToggleExpand" :selected-node="selectedNode" />
  </ul>
</template>

<script>
import TreeNode from '@/components/TreeNode.vue';
import { ref } from 'vue';

const KEY_FIELD = "id";
const LABEL_FIELD = "title";
const CHILDREN_FIELD = "subTreeNodeResp";


export default {
  components: {
    TreeNode,
  },
  props: {
    treeData: {
      type: Array,
      required: true,
    },
    defaultExpandKeys: {
      type: Array,
      default: () => []
    },
    selectedNode: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      expandedSet: ref(new Set(this.defaultExpandKeys)),
    };
  },
  computed: {
    flattenTree() {
      const res = [];
      const dfs = (tree) => {
        tree.forEach((node) => {
          res.push(node);
          if (this.expandedSet.has(node.id)) {
            dfs(node.children);
          }
        });
      };
      dfs(this.formatTreeData(this.treeData));
      return res;
    },
  },
  methods: {
    onSelect(node) {
      this.$emit('selectNode', node)
    },
    formatTreeData(data, parent) {
      return data.map((item) => {
        if (Object.keys(item).length === 0 && item.constructor === Object) {
          return null;
        }

        const children = item[CHILDREN_FIELD] || [];
        const treeNode = {
          id: item[KEY_FIELD],
          title: item[LABEL_FIELD],
          children: [],
          level: parent ? parent.level + 1 : 0,
          parentKey: parent ? parent.key : null,
          leaf: item.isLeaf !== void 0 ? item.isLeaf : children.length === 0,
        };

        if (children.length) {
          treeNode.children = this.formatTreeData(children, treeNode);
        }
        return treeNode;
      }).filter(item => item !== null);
    },
    onToggleExpand(node) {
      if (this.expandedSet.has(node[KEY_FIELD])) {
        this.expandedSet.delete(node[KEY_FIELD]);
      } else {
        this.expandedSet.add(node[KEY_FIELD]);
      }
    }
  },
};
</script>
