<template>

    <div class="h-screen w-screen relative bg-white flex">
  
  
      <!-- 移动端 -->
      <main 
        class="flex-grow bg-gray-200 dark:bg-gray-300 p-4 transition-width duration-300 ease-in-out sm:hidden">
        <!-- 主内容区域 -->
        <div class="space-y-6 mt-5 text-left lg:max-w-4xl lg:mx-auto">
          <!-- <h2 class="text-2xl font-medium">{{ selectedNode.title }}</h2> -->
          <div v-if="! node || ! node.content" class="text-4xl text-red-400">题库更新中，敬请期待！</div>
  
          <div v-else>
            <p class="text-4xl text-center mb-5">{{ node.name }}</p>
            <BitfreeMdPreview class="pt-3" :md-text="node.content" />
          </div>
  
        </div>
      </main>
  
      <!-- PC端 -->
      <main class="flex-grow bg-gray-200 dark:bg-gray-300 p-4 transition-width duration-300 ease-in-out hidden sm:block">
        <!-- 主内容区域 -->
        <div class="space-y-6 mt-5 text-left lg:max-w-4xl lg:mx-auto">
          <!-- <h2 class="text-2xl font-medium">{{ selectedNode.title }}</h2> -->
          <div v-if="! node || ! node.content" class="text-4xl text-red-400">答案更新中，敬请期待！</div>
          <div v-else>
            <p class="text-4xl text-center">{{ node.name }}</p>
            <BitfreeMdPreview class="m-5" :md-text="node.content" />
          </div>
        </div>
      </main>
  
    </div>
  
  
  </template>
  
  <script>
  import TreeNav from '@/components/TreeNav.vue';
  import BitfreeMdPreview from './BitfreeMdPreview.vue';
  import { getQuestionDetail, getQuestionTree } from '@/openapi/question';
  
  export default {
    components: {
      TreeNav,
      BitfreeMdPreview,
    },
    data() {
      return {
        id : null,
        node : {}
      };
    },
    methods: {
      async fetchContent(id) {
        try {
          const res = await getQuestionDetail({ id });
          this.content = res.data.data.content;
        } catch (error) {
          console.error("Error fetching content:", error);
          this.content = ""; // 清空内容，以便显示“加急更新中”
        }
      },
      async onSelectNode(node) {
        this.selectedNode = node;
        await this.fetchContent(node.id);
      },
      toggleTreeVisibility() {
        this.isTreeVisible = !this.isTreeVisible;
      },
      openQuestionForm() {
        this.$router.push('/new-question');
      },
      performSearch() {
        // 实现搜索逻辑
        // this.fetchPosts(this.currentPage, this.searchText)
      },
      toggleTreeVisibilityMobile() {
        this.isTreeVisible = true
        this.selectedNode = null
      }
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        this.id = urlParams.get('id');

        getQuestionDetail({ id: this.id }).then(res => {
            this.node = res.data.data
        })
    }
  };
  </script>
  
  <style scoped>
  /* 添加适当的CSS样式 */
  </style>