import { createApp } from 'vue/dist/vue.esm-bundler';
import { config } from 'md-editor-v3';

import App from './App.vue'
import router from './router/router'
import axios from 'axios'
import scrollTo from 'vue-scrollto'
import './input.css'
import '@element-plus/icons-vue';
import * as globalFunctions from './global.js'
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';


const app = createApp(App);
app.config.globalProperties.$axios = axios
app.config.globalProperties.$formatDate = globalFunctions.formatDate;

app.use(router).use(scrollTo).use(VueVideoPlayer).mount('#app')

config({
    markdownItPlugins(plugins) {
        return plugins.filter((p) => p.type !== 'xss');
    }
});