<template>
    <div class="min-h-screen flex flex-col">
        <!-- 主体内容 -->
        <main class="flex-1 flex justify-center mt-10">
            <div class="container mx-auto px-4 flex flex-col md:flex-row">
                <!-- 左侧栏 -->
                <!-- 右侧主内容区 -->

                <div class="container mx-auto px-4 sm:px-6 lg:px-8">
                    <form @submit.prevent="saveQuestion" class="bg-white shadow-md rounded-md space-y-6 p-10">
                        <h2 class="text-2xl font-semibold text-gray-800">发布题目</h2>
                        <div>
                            <input type="text" id="name" v-model="name" required placeholder="标题"
                                class="mt-1 block w-full rounded-md border-2 border-gray-400 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-4 py-2 bg-gray-50" />
                        </div>
                        <div>
                            <BitfreeMdEditor v-model:md-text="content" />
                        </div>

                        <div v-if="errorMessage" class="mt-4 p-2 bg-red-100 rounded-lg text-red-800">
                            <p>{{ errorMessage }}</p>
                            <button @click="clearErrorMessage"
                                class="ml-2 px-2 py-1 rounded-md text-red-600 hover:text-red-700">关闭</button>
                        </div>

                        <div class="flex justify-end m-4">
                            <button type="submit"
                                class="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-md">保存</button>
                        </div>
                    </form>
                </div>
                <!-- </section> -->
            </div>
        </main>
    </div>


</template>

<script>
import BitfreeMdEditor from '@/components/BitfreeMdEditor.vue';
import { addQuestion } from '@/openapi/question';

import mindmap from "vue3-mindmap";
import "vue3-mindmap/dist/style.css";
import { getQuestionTree } from '@/openapi/question';


export default {
    components: {
        BitfreeMdEditor
    },
    data() {
        return {
            name: '',
            content: '',
            errorMessage: ''
        };
    },
    methods: {
        clearErrorMessage() {
            this.errorMessage = '';
        },
        validateContent() {
            if (!this.content || this.content.trim() === '') {
                this.errorMessage = '内容不能为空。';
                return false;
            }

            const minLength = 10;
            if (this.content.length < minLength) {
                this.errorMessage = `内容不能少于 ${minLength} 个字符。`;
                return false;
            }

            this.errorMessage = '';
            return true;
        },
        async saveQuestion() {
            if (!this.validateContent()) {
                return;
            }

            // 弹出确认对话框
            const isConfirmed = confirm('确定要保存这篇文章吗？');
            if (isConfirmed) {
                this.performSave();
            }
        },
        async performSave() {
            try {
                const resp = await addQuestion({
                    name: this.name,
                    content: this.content
                });
                if (resp.data.code !== 200) return 
                this.name = '';
                this.content = '';
                alert('题目已保存！'); // 临时提示，实际应用中应替换为实际反馈
                this.$router.push('/question'); // 发帖成功后返回主页或列表页
            } catch (error) {
                console.error('Error saving the post:', error);
                alert('保存失败，请稍后再试。');
            }
        },
    },
    async created() {
        const resp = await getQuestionTree();
        this.data = resp.data.data; // 将 API 返回的数据赋值给 tree
        this.loading = false
    }
};
</script>


<style>
input[type="range"] {
    -webkit-appearance: none;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background-color: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
}

.Mindmap_fit_fgvb6,
.Mindmap_gps_fgvb6,
.Mindmap_download_fgvb6 {
    border: 1px solid red;
    background: black;
}
</style>
