export const emojis = ['[doge]', '[doge2]', '[e]', '[ch]', '[ok]', '[口罩]', '[吃瓜]', '[呆]', '[呲牙]', '[哦呼]', '[喜极而泣]',
    '[喜欢]', '[嗑瓜子]', '[嘟嘟]', '[大哭]', '[大笑]', '[委屈]', '[害羞]', '[尴尬]', '[微笑]', '[思考]',
    '[惊讶]', '[打call]', '[抠鼻]', '[捂眼]', '[捂脸]', '[星星眼]', '[歪嘴]', '[滑稽]', '[点赞]', '[热]', '[冷]',
    '[疑惑]', '[秒啊]', '[笑哭]', '[给心心]', '[脱单doge]', '[脸红]', '[藏狐]', '[豹富]', '[辣眼睛]', '[郁闷]',
    '[酸了]', '[鼓掌]', '[傲娇]', '[再见]', '[哈欠]', '[嘘声]', '[墨镜]', '[奋斗]', '[嫌弃]', '[撇嘴]', '[无语]',
    '[惊喜]', '[抓狂]', '[拥抱]', '[怪我咯]', '[跪了]', '[灵魂出窍]', '[生气]', '[生病]', '[疼]', '[白眼]', '[笑]',
    '[知识增加]', '[调皮]', '[阴险]', '[难过]'];

export function replaceEmojiWithImages(content) {
    emojis.forEach(element => {
        if (content.indexOf(element) != -1) {
            const imgPath = element + '.webp'
            content = content.replaceAll(element, `<img src="static/emoji/${imgPath}" class="w-8 inline-block" />`);
        }
    });
    const imgTagPattern = /<img\s+src=["']([^"']+)["'].*?>/gi;

    // 使用函数进行替换，确保正确闭合标签
    const replacedContent = content.replace(imgTagPattern, (match, imgSrc) => {
        // 保留原有<img>标签属性，同时添加一个包裹的<a>标签
        return `<a href="${imgSrc}" target="_blank">${match}</a>`;
    });
    return replacedContent;
}

export function formatTime(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function formatTime2Day(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const imageSource = {
    CHAT: 'chat',
    POST: 'post',
    AVATAR: 'avatar'
}

export const taskStatus = {
    TODO: 0,
    DOING: 1,
    SUBMIT: 2,
    DONE: 3
}

export const userLevel = {
    COMMUNITY: 1,
    ULTIMATE: 2,
    ADMIN: 3
}