const REDIRECTING_KEY = 'isRedirecting';
const LOGGEDIN_KEY = 'isLoggedIn';

let isRedirecting = localStorage.getItem(REDIRECTING_KEY) === 'true';
let isLoggedIn = localStorage.getItem(LOGGEDIN_KEY) === 'true';

export const setRedirecting = (value) => {
    isRedirecting = value;
    localStorage.setItem(REDIRECTING_KEY, value);
};

export const getRedirecting = () => {
    return isRedirecting;
};

export const setLoggedIn = (value) => {
    isLoggedIn = value;
    localStorage.setItem(LOGGEDIN_KEY, value);
};

export const getLoggedIn = () => {
    return isLoggedIn;
};
