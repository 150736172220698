<template>
    <mindmap v-if="!loading" style="height: 100vh" v-model="data" @click="clickNode" :locale="locale" :branch="4"
        :x-gap="84" :y-gap="18" :zoom="true" :fit-btn="true" :center-btn="true" :download-btn="true" :drag="false"
        :edit="edit" :add-node-btn="true" :sharp-corner="true" :ctm="true" :timetravel="false" :vertical="true" />

    <div class="fixed bottom-8 right-8 z-10">
        <div class="flex items-center">
            <button @click="openQuestionForm"
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                添加
            </button>
        </div>
    </div>
</template>

<script>
import mindmap from "vue3-mindmap";
import "vue3-mindmap/dist/style.css";
import { getQuestionTree, addQuestion } from '@/openapi/question';


export default {
    name: "App",
    components: { mindmap },
    data() {
        return {
            loading: true,
            data: null,
            locale: "zh-CN",
            edit: false,
            tree: null
        };
    },
    methods: {
        // dfs(data) {
        //     if (!data) return null
        //     for (let i = 0; i < data.length; i++) {
        //         if (! data[i]) return 
        //         this.addName.push(data[i].name)
        //         this.dfs(data[i].children);
        //     }
        // },
        findNode(name, tree) {
            if (!tree) return null
            for (let i = 0; i < tree.length; i++) {
                if (tree[i].name === name) {
                    return tree[i];
                }
                const foundNode = this.findNode(name, tree[i].children);
                if (foundNode) {
                    return foundNode;
                }
            }
            return null
        },
        getAddNode(data, parent) {
            if (!data) return null
            for (var i = 0; i < data.length; i++) {

                if (!data[i].id) {
                    for (var p in data[i]) {
                        console.log(p)
                    }
                    console.log("find id is null node ", data[i].id, data[i].name, data[i], JSON.stringify(data[i]), 'id' in data[i], data[i])
                    return { parent: parent, curNode: data[i] }
                }
                var node = this.getAddNode(data[i].children, data[i])
                if (node) return node;
            }
            return null;
        },
        async clickNode(node) {
            if (!node) return;
            if (this.edit) {
                console.log("all node ", this.data)
                var addNode = this.getAddNode(this.data, null)

                // 点击展开并不需要跳转
                if (addNode && addNode.curNode.name !== '') {
                    const resp = await addQuestion({ name: addNode.curNode.name, parentId: addNode.parent?.id })
                    // location.reload()
                    // this.addName.push(addNode.curNode.name)
                    Vue.set(addNode.curNode, 'id', resp.data.data)
                    return
                }
            }


            if (node.target.tagName !== 'tspan' || !node.target?.__data__) return
            // 展开
            const name = node.target.__data__.name
            const clickNode = this.findNode(name, this.data)
            if (clickNode) {
                const url = `/questionDetail?id=${clickNode.id}`;
                window.open(url, '_blank');
            } else {
                console.error('Tree or tree.id is not defined');
            }
        },
        openQuestionForm() {
            this.$router.push('/new-question');
        },
        change(node) {
            console.log("change node ", node)
        }
    },
    async created() {
        const resp = await getQuestionTree();
        this.data = resp.data.data; // 将 API 返回的数据赋值给 tree
        const user = JSON.parse(localStorage.getItem('user'))
        this.edit = user.canVerified
        this.loading = false
    }
};
</script>

<style>
.Mindmap_fit_fgvb6,
.Mindmap_gps_fgvb6,
.Mindmap_download_fgvb6 {
    border: 1px solid red;
    background: black;
}
</style>