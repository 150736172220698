import http from "@/plugins/http";

export async function getList(params) {
    return http.get('/taskBoard/getTaskBoardList', { params });
}

export async function updateTask(params) {
    return http.post('/taskBoard/updateTask', params);
}

export async function canVerify() {
    return http.get('/taskBoard/canVerify');
}


